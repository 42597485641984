/* eslint-disable */
// Automatically generated
export default {
  "reakit": require("reakit"),
  "reakit/Box": require("reakit/Box"),
  "reakit/Box/Box": require("reakit/Box/Box"),
  "reakit/Button": require("reakit/Button"),
  "reakit/Button/Button": require("reakit/Button/Button"),
  "reakit/Checkbox": require("reakit/Checkbox"),
  "reakit/Checkbox/Checkbox": require("reakit/Checkbox/Checkbox"),
  "reakit/Checkbox/CheckboxState": require("reakit/Checkbox/CheckboxState"),
  "reakit/Clickable": require("reakit/Clickable"),
  "reakit/Clickable/Clickable": require("reakit/Clickable/Clickable"),
  "reakit/Combobox": require("reakit/Combobox"),
  "reakit/Combobox/Combobox": require("reakit/Combobox/Combobox"),
  "reakit/Combobox/ComboboxGridCell": require("reakit/Combobox/ComboboxGridCell"),
  "reakit/Combobox/ComboboxGridRow": require("reakit/Combobox/ComboboxGridRow"),
  "reakit/Combobox/ComboboxGridState": require("reakit/Combobox/ComboboxGridState"),
  "reakit/Combobox/ComboboxItem": require("reakit/Combobox/ComboboxItem"),
  "reakit/Combobox/ComboboxList": require("reakit/Combobox/ComboboxList"),
  "reakit/Combobox/ComboboxListGridState": require("reakit/Combobox/ComboboxListGridState"),
  "reakit/Combobox/ComboboxListState": require("reakit/Combobox/ComboboxListState"),
  "reakit/Combobox/ComboboxOption": require("reakit/Combobox/ComboboxOption"),
  "reakit/Combobox/ComboboxPopover": require("reakit/Combobox/ComboboxPopover"),
  "reakit/Combobox/ComboboxState": require("reakit/Combobox/ComboboxState"),
  "reakit/Composite": require("reakit/Composite"),
  "reakit/Composite/Composite": require("reakit/Composite/Composite"),
  "reakit/Composite/CompositeGroup": require("reakit/Composite/CompositeGroup"),
  "reakit/Composite/CompositeItem": require("reakit/Composite/CompositeItem"),
  "reakit/Composite/CompositeItemWidget": require("reakit/Composite/CompositeItemWidget"),
  "reakit/Composite/CompositeState": require("reakit/Composite/CompositeState"),
  "reakit/Dialog": require("reakit/Dialog"),
  "reakit/Dialog/Dialog": require("reakit/Dialog/Dialog"),
  "reakit/Dialog/DialogBackdrop": require("reakit/Dialog/DialogBackdrop"),
  "reakit/Dialog/DialogDisclosure": require("reakit/Dialog/DialogDisclosure"),
  "reakit/Dialog/DialogState": require("reakit/Dialog/DialogState"),
  "reakit/Disclosure": require("reakit/Disclosure"),
  "reakit/Disclosure/Disclosure": require("reakit/Disclosure/Disclosure"),
  "reakit/Disclosure/DisclosureContent": require("reakit/Disclosure/DisclosureContent"),
  "reakit/Disclosure/DisclosureState": require("reakit/Disclosure/DisclosureState"),
  "reakit/Form": require("reakit/Form"),
  "reakit/Form/Form": require("reakit/Form/Form"),
  "reakit/Form/FormCheckbox": require("reakit/Form/FormCheckbox"),
  "reakit/Form/FormGroup": require("reakit/Form/FormGroup"),
  "reakit/Form/FormInput": require("reakit/Form/FormInput"),
  "reakit/Form/FormLabel": require("reakit/Form/FormLabel"),
  "reakit/Form/FormMessage": require("reakit/Form/FormMessage"),
  "reakit/Form/FormPushButton": require("reakit/Form/FormPushButton"),
  "reakit/Form/FormRadio": require("reakit/Form/FormRadio"),
  "reakit/Form/FormRadioGroup": require("reakit/Form/FormRadioGroup"),
  "reakit/Form/FormRemoveButton": require("reakit/Form/FormRemoveButton"),
  "reakit/Form/FormState": require("reakit/Form/FormState"),
  "reakit/Form/FormSubmitButton": require("reakit/Form/FormSubmitButton"),
  "reakit/Form/utils": require("reakit/Form/utils"),
  "reakit/Form/utils/getIn": require("reakit/Form/utils/getIn"),
  "reakit/Form/utils/setAllIn": require("reakit/Form/utils/setAllIn"),
  "reakit/Form/utils/setIn": require("reakit/Form/utils/setIn"),
  "reakit/Grid": require("reakit/Grid"),
  "reakit/Grid/Grid": require("reakit/Grid/Grid"),
  "reakit/Grid/GridCell": require("reakit/Grid/GridCell"),
  "reakit/Grid/GridRow": require("reakit/Grid/GridRow"),
  "reakit/Grid/GridState": require("reakit/Grid/GridState"),
  "reakit/Group": require("reakit/Group"),
  "reakit/Group/Group": require("reakit/Group/Group"),
  "reakit/Id": require("reakit/Id"),
  "reakit/Id/Id": require("reakit/Id/Id"),
  "reakit/Id/IdProvider": require("reakit/Id/IdProvider"),
  "reakit/Id/IdState": require("reakit/Id/IdState"),
  "reakit/Input": require("reakit/Input"),
  "reakit/Input/Input": require("reakit/Input/Input"),
  "reakit/Menu": require("reakit/Menu"),
  "reakit/Menu/Menu": require("reakit/Menu/Menu"),
  "reakit/Menu/MenuArrow": require("reakit/Menu/MenuArrow"),
  "reakit/Menu/MenuBar": require("reakit/Menu/MenuBar"),
  "reakit/Menu/MenuBarState": require("reakit/Menu/MenuBarState"),
  "reakit/Menu/MenuButton": require("reakit/Menu/MenuButton"),
  "reakit/Menu/MenuDisclosure": require("reakit/Menu/MenuDisclosure"),
  "reakit/Menu/MenuGroup": require("reakit/Menu/MenuGroup"),
  "reakit/Menu/MenuItem": require("reakit/Menu/MenuItem"),
  "reakit/Menu/MenuItemCheckbox": require("reakit/Menu/MenuItemCheckbox"),
  "reakit/Menu/MenuItemRadio": require("reakit/Menu/MenuItemRadio"),
  "reakit/Menu/MenuSeparator": require("reakit/Menu/MenuSeparator"),
  "reakit/Menu/MenuState": require("reakit/Menu/MenuState"),
  "reakit/Popover": require("reakit/Popover"),
  "reakit/Popover/Popover": require("reakit/Popover/Popover"),
  "reakit/Popover/PopoverArrow": require("reakit/Popover/PopoverArrow"),
  "reakit/Popover/PopoverBackdrop": require("reakit/Popover/PopoverBackdrop"),
  "reakit/Popover/PopoverDisclosure": require("reakit/Popover/PopoverDisclosure"),
  "reakit/Popover/PopoverState": require("reakit/Popover/PopoverState"),
  "reakit/Portal": require("reakit/Portal"),
  "reakit/Portal/Portal": require("reakit/Portal/Portal"),
  "reakit/Provider": require("reakit/Provider"),
  "reakit/Radio": require("reakit/Radio"),
  "reakit/Radio/Radio": require("reakit/Radio/Radio"),
  "reakit/Radio/RadioGroup": require("reakit/Radio/RadioGroup"),
  "reakit/Radio/RadioState": require("reakit/Radio/RadioState"),
  "reakit/Role": require("reakit/Role"),
  "reakit/Role/Role": require("reakit/Role/Role"),
  "reakit/Rover": require("reakit/Rover"),
  "reakit/Rover/Rover": require("reakit/Rover/Rover"),
  "reakit/Rover/RoverState": require("reakit/Rover/RoverState"),
  "reakit/Separator": require("reakit/Separator"),
  "reakit/Separator/Separator": require("reakit/Separator/Separator"),
  "reakit/Tab": require("reakit/Tab"),
  "reakit/Tab/Tab": require("reakit/Tab/Tab"),
  "reakit/Tab/TabList": require("reakit/Tab/TabList"),
  "reakit/Tab/TabPanel": require("reakit/Tab/TabPanel"),
  "reakit/Tab/TabState": require("reakit/Tab/TabState"),
  "reakit/Tabbable": require("reakit/Tabbable"),
  "reakit/Tabbable/Tabbable": require("reakit/Tabbable/Tabbable"),
  "reakit/Toolbar": require("reakit/Toolbar"),
  "reakit/Toolbar/Toolbar": require("reakit/Toolbar/Toolbar"),
  "reakit/Toolbar/ToolbarItem": require("reakit/Toolbar/ToolbarItem"),
  "reakit/Toolbar/ToolbarSeparator": require("reakit/Toolbar/ToolbarSeparator"),
  "reakit/Toolbar/ToolbarState": require("reakit/Toolbar/ToolbarState"),
  "reakit/Tooltip": require("reakit/Tooltip"),
  "reakit/Tooltip/Tooltip": require("reakit/Tooltip/Tooltip"),
  "reakit/Tooltip/TooltipArrow": require("reakit/Tooltip/TooltipArrow"),
  "reakit/Tooltip/TooltipReference": require("reakit/Tooltip/TooltipReference"),
  "reakit/Tooltip/TooltipState": require("reakit/Tooltip/TooltipState"),
  "reakit/VisuallyHidden": require("reakit/VisuallyHidden"),
  "reakit/VisuallyHidden/VisuallyHidden": require("reakit/VisuallyHidden/VisuallyHidden")
};
