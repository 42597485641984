import * as React from "react";

function Accessible(
  props: React.SVGAttributes<SVGElement>,
  ref: React.Ref<any>
) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props} ref={ref}>
      <g id="surface1_41_">
        <path
          fill="#3F51B5"
          d="M24,4C13,4,4,13,4,24s9,20,20,20c11,0,20-9,20-20S35,4,24,4z"
        />
        <path
          fill="#FFFFFF"
          d="M24,12.5c1.5,0,2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8S22.5,12.5,24,12.5z M32.2,20.4   c-1.7,0.3-3.5,0.4-5.2,0.5c-0.6,0-1,0.4-1,0.8c-0.2,5.4,2,11.9,2.1,11.9c0.2,0.5-0.1,1.1-0.6,1.3c-0.1,0-0.2,0.1-0.3,0.1   c-0.4,0-0.8-0.3-0.9-0.7c-0.1-0.2-0.9-2.9-1.6-6.4c-0.2-0.9-0.8-0.8-0.9,0c-0.6,3.5-1.6,6.2-1.6,6.4C21.8,34.8,21.5,35,21,35   c-0.1,0-0.2,0-0.3-0.1c-0.5-0.2-0.8-0.8-0.6-1.3c0-0.1,2.2-6.5,2.1-11.9c0-0.4-0.4-0.7-1-0.8c-3.1-0.1-5.2-0.4-5.4-0.4   c-0.5-0.1-0.9-0.6-0.9-1.1c0.1-0.6,0.6-0.9,1.1-0.9c0.1,0,8.6,1,15.9-0.2c0.5-0.1,1.1,0.3,1.1,0.8C33.2,19.8,32.8,20.4,32.2,20.4z"
        />
        <path
          fill="#FFFFFF"
          d="M24,41c-9.4,0-17-7.6-17-17S14.6,7,24,7s17,7.6,17,17S33.4,41,24,41z M24,9C15.7,9,9,15.7,9,24   c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C39,15.7,32.3,9,24,9z"
        />
      </g>
    </svg>
  );
}

export default React.forwardRef(Accessible);
