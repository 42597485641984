/* eslint-disable */
// Automatically generated
export default {
  "reakit-playground": require("reakit-playground"),
  "reakit-playground/ErrorBoundary": require("reakit-playground/ErrorBoundary"),
  "reakit-playground/ErrorMessage": require("reakit-playground/ErrorMessage"),
  "reakit-playground/PlaygroundEditor": require("reakit-playground/PlaygroundEditor"),
  "reakit-playground/PlaygroundPreview": require("reakit-playground/PlaygroundPreview"),
  "reakit-playground/system": require("reakit-playground/system"),
  "reakit-playground/usePlaygroundState": require("reakit-playground/usePlaygroundState")
};
